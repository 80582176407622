




.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* background-color: #fff; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */
}

.add-header-bg {
  box-shadow: 0px 4px 15px rgb(0 0 0 / 8%);
  transition-duration: 0.4s;
  background: #fff;
  padding-top: 0;
  margin: 0;
}


.desktop-menu {
  display: block;
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }
}

:where(.css-dev-only-do-not-override-pr0fja).ant-menu-dark .ant-menu-item-selected,
 :where(.css-dev-only-do-not-override-pr0fja).ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #f5f5f4 !important;
 }


.ant-menu-light .ant-menu-item:hover {
  color: #000 !important; 
}
.ant-menu-light .ant-menu-item-selected{
  /* background:transparent; */
  background: #f5f5f4;
  color:#000 !important;
}

.ant-menu-light .ant-menu-item-selected:hover {
    color: #000 !important;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,.ant-tabs .ant-tabs-tab:hover {
    font-family: 'Poppins';
  }
 

 
 /* for side bar menu icon */
.ant-layout .ant-layout-sider-zero-width-trigger {
  top:0px;
 }

.header{
  background-color:#fff;
  padding:0px 30px;
  display: flex;
  justify-content: space-between;
  width:100%;
}


.ant-tabs .ant-tabs-tab-btn,
.ant-form-item .ant-form-item-label >label{
  font-family: 'Poppins', sans-serif;
 }

 /* for input field placeholder */
 .ant-input {
  font-family: 'Poppins', sans-serif;
 }

 .ant-card {
  border:none !important;
 }

 .panel-header-active {
  background-color: black;
  color: white;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  padding:7px;
}

.btn-bg{
  background: #00d094;
}

.card-bg{
  background: #fafafb;
}

/* Quiz */

.quiz-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  width: 400px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 2px 2px 10px 1px #d2fae3;
  overflow: hidden;
  padding: 10px;
  position: relative;
  margin: auto; 
  margin-top: 50px; 
}

.heading-txt{
  color:#00d094;
  font-size: 24px;
  margin: 10px;
  text-align: center;  
  text-transform: uppercase;
}

.option-container{
  display: flex;
  flex-direction: column;
  margin:6px 4px;    
  width: 100%;
  padding: 12px;
}
/* .option-container input{
  visibility: hidden;    
} */
.option-btn{
  box-shadow: 0px 0px 4px 1px #d2fae3;
  padding: 20px;
  border-radius: 3px;
  border:none;
  outline: none;
  transition: 0.3s;
  margin: 5px;
  min-height: 30px;
  background-color: white;
      
}

.checkbox-btn{
  line-height: 0.5;
}

.otpInput {
  width: 40px;
  height: 40px;
  margin: 5px;
  text-align: center;
  font-size: 1.2em;
}
/* .checked{
  background-color: rgb(61, 244, 70);
  color:white;
  box-shadow: 3px 3px 4px 1px rgb(50, 239, 100);
} */



/* .option-btn:hover{
  background-color: #1af077;
  color:white;
} */

/* Profile */

.second-line-indent {
  margin-left: 20px; /* Adjust the value as needed */
}

/* For sip calculator */

/* Change the track color */
.custom-slider .ant-slider-track {
  background-color: #00d094 !important; /* Change the track color */
}

/* Change the handle border color */
.custom-slider .ant-slider-handle {
  border-color: #fff !important; /* Change the handle border color */
}

/* Change the handle background color */
.custom-slider .ant-slider-handle::after {
  background-color: #fff !important; /* Change the handle background color */
}

/* Change the track color on hover */
.custom-slider .ant-slider-track:hover {
  background-color: #00d094 !important; /* Change the track color on hover */
}

/* Change the handle border color on hover */
.custom-slider .ant-slider-handle:hover {
  border-color: #fff !important; /* Change the handle border color on hover */
}

/* Change the handle background color on hover */
.custom-slider .ant-slider-handle:hover::after {
  background-color: #fff !important; /* Change the handle background color on hover */
}

.skeleton-loader {
  position: relative;
}

.skeleton-bg,
.skeleton-coin,
.skeleton-heading,
.skeleton-moto,
.skeleton-btn,
.skeleton-social,
.skeleton-thumb{
  background-color: #e0e0e0;
  border-radius: 4px;
  opacity: 0.7;
  margin-bottom: 1rem;
}

.skeleton-bg {
  height: 80px; /* adjust as necessary */
}

.skeleton-coin {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

.skeleton-heading {
  height: 4rem;
  width: 80%;
  margin-bottom: 1rem;
}

.skeleton-moto {
  height: 1rem;
  width: 80%;
  margin-bottom: 1rem;
}

.skeleton-btn {
  height: 40px;
  width: 120px;
  margin-right: 10px;
}

.skeleton-btn.outline {
  background-color: transparent;
  border: 1px solid #e0e0e0;
}

.skeleton-social {
  display: flex;
  gap: 10px;
  margin-top: 1rem;
}

.skeleton-thumb {
  height: 500px; 
}

